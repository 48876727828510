import axios from '@/plugins/axios'

export default {

  async list (data) {
    const params = new URLSearchParams(data).toString()
    return await axios.get(`announcements?${params}`)
  },

  async get (id) {
    return await axios.get(`announcements/${id}`)
  },

  async insert (data) {
    return await axios.post('announcements', data)
  },

  async testEmail (data) {
    return await axios.post('announcements/test-email', data)
  },

}
