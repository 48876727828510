<template>
  <div>
    <core-page-title />

    <v-container class="mt-3">
      <v-row>
        <v-col>
          <v-btn
            class="mr-2"
            color="info"
            dark
            @click="openDialog()"
          >
            <v-icon
              left
              v-text="'mdi-email-plus'"
            />
            Novo
          </v-btn>

          <v-btn
            v-if="$route.query.token && $route.query.token === '7zUlZjwsA1FlLKcjANJ6Yd'"
            color="red"
            dark
            @click="testEmail()"
          >
            <v-icon
              left
              v-text="'mdi-alert'"
            />
            Testar E-mail
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col>
          <v-sheet elevation="1">
            <v-data-table
              disable-sort
              :server-items-length="count"
              :loading="loading"
              :headers="headers"
              :items="announcements"
              :options.sync="pagination"
              :items-per-page="20"
              :footer-props="{
                'items-per-page-options': [10, 20, 50, 75]
              }"
              @update:options="loadAnnouncements"
            >
              <template
                v-slot:[`item.createdAt`]="{ item }"
              >
                {{ item.createdAt | date('DD/MM/YYYY HH:mm') }}
              </template>

              <template
                v-slot:[`item.actions`]="{ item }"
                class="text-right"
              >
                <v-btn
                  icon
                  small
                  @click="openDialog(item)"
                >
                  <v-icon
                    v-text="'mdi-eye'"
                    small
                  />
                </v-btn>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <dialog-announcement
      v-model="showDialogAnnouncement"
      :announcement-id="editId"
      @save="loadAnnouncements"
    />

  </div>
</template>

<script>
  import announcementsApi from '@/api/announcements'



  export default {

    components: {
      DialogAnnouncement: () => import('@/components/dialog/announcements/DialogAnnouncement'),
    },

    data () {
      return {
        loading: false,
        showDialogAnnouncement: false,
        count: 0,
        headers: [
          { text: '#', value: 'id' },
          { text: 'Assunto', value: 'subject' },
          { text: 'Data', value: 'createdAt' },
          { text: '', value: 'actions', align: 'right' },
        ],
        announcements: [],
        pagination: {},
        editId: null,
      }
    },

    methods: {
      async loadAnnouncements () {
        try {
          this.loading = true

          const response = await announcementsApi.list({
            limit: this.pagination.itemsPerPage,
            offset: (this.pagination.page * this.pagination.itemsPerPage) - this.pagination.itemsPerPage,
          })

          this.announcements = response.data.announcements
          this.count = response.data.count
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async testEmail () {
        const response = await announcementsApi.testEmail()
      },

      openDialog (announcement = null) {
        this.editId = announcement ? announcement.id : null
        this.showDialogAnnouncement = true
      },

    },

  }
</script>
